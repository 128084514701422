var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Setup-Step',{attrs:{"loadingMsg":_vm.loadingMsg,"settings":_vm.settings,"showRestart":_vm.stage != 0,"showSkip":_vm.stage == 0,"step":"supply-pointers","title":"Location Supply Areas"},on:{"restart":function($event){_vm.stage = 0}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('Setup-Block',[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}},[_c('v-tab-item',[_c('p',[_vm._v(" You can automatically give a consignment a supply location by setting the area that each of your supplying locations cover. ")]),_c('p',{staticClass:"my-4"},[_vm._v("Press 'set area' to set the supply area for each location.")]),_c('BT-List',{staticClass:"mb-4",attrs:{"canSearch":false,"dense":"","loadingMsg":_vm.loadingMsg,"maxHeight":"60vh","navigation":"locations"},on:{"update:loadingMsg":function($event){_vm.loadingMsg=$event},"update:loading-msg":function($event){_vm.loadingMsg=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.locationName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toLocationLine")(item)))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.setAreaFor(item)}}},[_vm._v("set area")])],1)]}}])}),_c('Setup-Example',[_vm._v(" Creative Crayons LTD has one location in Terang and another in Timboon. A customer places an order to be delivered to Silvester St in Cobden. If Creative Crayons LTD sets the supply area of their Terang location to cover Cobden, when this order is consigned it will automatically be given the departure location of the Terang site. ")])],1),(_vm.newItem != null)?_c('v-tab-item',[_c('p',[_vm._v(_vm._s(_vm.newItem.locationName))]),_c('GmapMap',{ref:"locMap",staticStyle:{"width":"100%","height":"65vh"},attrs:{"center":_vm.newItem,"zoom":7,"options":{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }}},[(_vm.newItem != null && _vm.newItem.lat != null && _vm.newItem.lng != null)?_c('gmap-marker',{attrs:{"position":{ lat: _vm.newItem.lat, lng: _vm.newItem.lng }}}):_vm._e(),_vm._l((_vm.otherPointers),function(pointer,index){return _c('gmap-polygon',{key:index + 'a',attrs:{"fillOpacity":0.5,"paths":pointer.boundary,"strokeWeight":1}})}),_vm._l((_vm.pointers),function(pointer,index){return _c('gmap-polygon',{key:index + 'b',attrs:{"clickable":"","editable":"","fillOpacity":0.5,"paths":pointer.boundary,"strokeWeight":1},on:{"paths_changed":function ($event) { _vm.updatePointerPaths($event, pointer) }}})})],2),_c('BT-Btn',{attrs:{"buttonClass":"primary mx-1 mt-4","inline":"","label":"Done"},on:{"click":_vm.save}})],1):_vm._e()],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }