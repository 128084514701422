<template>
    <Setup-Step
        @restart="stage = 0"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showRestart="stage != 0"
        :showSkip="stage == 0"
        step="supply-pointers"
        title="Location Supply Areas">
        <template v-slot:body>
            <Setup-Block>
                <v-tabs-items v-model="stage" touchless>
                    <v-tab-item>
                        
                        <p>
                            You can automatically give a consignment a supply location by setting the area that each of your supplying locations cover.
                        </p>

                        <p class="my-4">Press 'set area' to set the supply area for each location.</p>

                        <BT-List
                            :canSearch="false"
                            class="mb-4"
                            dense
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="60vh"
                            navigation="locations">
                            <template v-slot="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small text @click="setAreaFor(item)">set area</v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-List>
                        
                        <Setup-Example>
                            Creative Crayons LTD has one location in Terang and another in Timboon.  A customer places an order to be delivered to Silvester St in Cobden.  If Creative Crayons LTD sets the supply area of their Terang location to cover Cobden, when this order is consigned it will automatically be given the departure location of the Terang site.
                        </Setup-Example>
                    </v-tab-item>

                    <v-tab-item v-if="newItem != null">
                        <p>{{ newItem.locationName }}</p>
                        <GmapMap
                            ref="locMap"
                            :center="newItem"
                            :zoom="7"
                                style="width: 100%; height: 65vh;"
                                :options="{
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUi: false }">
                                    
                                <gmap-marker v-if="newItem != null && newItem.lat != null && newItem.lng != null" :position="{ lat: newItem.lat, lng: newItem.lng }" />

                                <gmap-polygon
                                    v-for="(pointer, index) in otherPointers"
                                    :fillOpacity="0.5"
                                    :key="index + 'a'"
                                    :paths="pointer.boundary"
                                    :strokeWeight="1" />

                                <gmap-polygon
                                    v-for="(pointer, index) in pointers"
                                    @paths_changed="($event) => { updatePointerPaths($event, pointer) }"
                                    clickable
                                    editable
                                    :fillOpacity="0.5"
                                    :key="index + 'b'"
                                    :paths="pointer.boundary"
                                    :strokeWeight="1" />
                        </GmapMap>

                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Done"
                            @click="save" />

                    </v-tab-item>
                </v-tabs-items>
            </Setup-Block>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Supply-Pointer-Setup',
    components: {
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        //SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            loadingMsg: null,
            newItem: null,
            otherPointers: [],
            pointers: [],
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        async setAreaFor(location) {
            this.newItem = location;
            var pointers = await this.$BlitzIt.store.getAll('supply-pointers');
            this.otherPointers = pointers.filter(x => x.boundary != null && x.locationID != location.id);
            this.pointers = pointers.filter(x => x.boundary != null && x.locationID == location.id);

            if (!this.isLengthyArray(this.pointers)) {
                this.pointers.push({
                    id: null,
                    rowVersion: null,
                    boundary: this.getAreaAround(location, 1),
                    pointerName: location.locationName + ' Supply Area',
                    weekdays: 'Always',
                    locationID: location.id,
                    location: location
                });
            }

            this.stage += 1;
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';

                for (let i = 0; i < this.pointers.length; i++) {
                    const pointer = this.pointers[i];
                    
                    if (pointer.changed == true) {
                        var res = null;
                        if (pointer.id == null) {
                            res = await this.$BlitzIt.store.post('supply-pointers', pointer);
                        }
                        else {
                            res = await this.$BlitzIt.store.patch('supply-pointers', pointer);
                        }

                        pointer.changed = false;
                        pointer.id = res.id;
                        pointer.rowVersion = res.rowVersion;
                    }
                }

                this.stage = 0;
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];

            zone.changed = true;
        },
    }
}
</script>